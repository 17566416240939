import React from 'react';
import { Link } from "gatsby";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./FooterNav.scss";


const FooterNav = (props) => {

  const { navItems, classes, copyright } = props

  return (
    <nav
      className={classNames("footer-bottom-menu", classes)}
    >
      <ul className="d-flex justify-content-between justify-content-lg-start">
        {navItems.map((menuItem) => (
          <li key={menuItem.object_slug} className="nav-item footer-nav-item">
            <Link
              to={`/${menuItem.object_slug}/`}
              className="nav-item-link text-link text-xs"
            >
              {menuItem.title}
            </Link>
          </li>
        ))}
      </ul>
      { copyright && (
        <span className="copyright-text text-xs">{ copyright }</span>
      )}
    </nav>
  )
}

FooterNav.propTypes = {
  navItems: PropTypes.instanceOf(Array).isRequired,
  classes: PropTypes.string,
};

FooterNav.defaultProps = {
  classes: ""
};

export default FooterNav

