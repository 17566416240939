/* eslint-disable consistent-return */
import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import FooterNav from "Layout/Footer/FooterNav/FooterNav"
import { ReactComponent as SvgIconTime } from "static/assets/brand/icons/Juto_uhr.svg";
import { ReactComponent as SvgIconMarker } from "static/assets/brand/icons/Juto_maps.svg";
import { ReactComponent as SvgIconPhone } from "static/assets/brand/icons/Juto_phone.svg";
import { ReactComponent as SvgIconMail } from "static/assets/brand/icons/Juto_email.svg";
import { ReactComponent as SvgIconFax } from "static/assets/brand/icons/Juto_fax.svg";
import { ReactComponent as SvgFooterLogo } from "static/assets/brand/logo/desktop/Juto_logo.svg";
import { ReactComponent as SvgFooterClimatePartner } from "static/assets/brand/icons/climatepartner.svg";
import "./Footer.scss";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      menus: allWordpressWpApiMenusMenusItems {
        edges {
          node {
            name
            items {
              title
              object_slug
              url
            }
          }
        }
      }
      optionsPage: allWordpressAcfOptions {
        edges {
          node {
            options {
              opening_times {
                opening_times {
                  opening_time_item
                }
              }
              footer {
                address {
                  address_item
                }
                contact {
                  contact_item
                }
              }
            }
          }
        }
      }
    }
  `)

  const { menus, optionsPage } = data
  const footerMenu = menus.edges[0];

  return (
    <footer className="footer-section mt-10 position-relative">
      <h2 className="social-links position-absolute">
        <span>Folge uns auf&nbsp;</span>
        <a href="https://www.facebook.com/indoorspielplatzjuxundtollerei/" className="text-transform-hover" target="_blank" rel="noopener noreferrer">
          <span className="letter-1">F</span>
          <span className="letter-2">A</span>
          <span className="letter-3">C</span>
          <span className="letter-4">E</span>
          <span className="letter-5">B</span>
          <span className="letter-6">O</span>
          <span className="letter-7">O</span>
          <span className="letter-8">K</span>
        </a>
        <span>&nbsp;und&nbsp;</span>
        <a href="https://www.instagram.com/jux_tollerei/" className="text-transform-hover" target="_blank" rel="noopener noreferrer">
          <span className="letter-1">I</span>
          <span className="letter-2">N</span>
          <span className="letter-3">S</span>
          <span className="letter-4">T</span>
          <span className="letter-5">A</span>
          <span className="letter-6">G</span>
          <span className="letter-7">R</span>
          <span className="letter-8">A</span>
          <span className="letter-9">M</span>
        </a>
      </h2>
      <div className="container footer-main mt-5 mt-md-3">
        <div className="row footer-info mb-md-2">
          <div className="col-12 col-lg-10 offset-lg-1 info-column">
            <div className="contact d-flex flex-column flex-lg-row row">
              <div className="col-10 col-lg-4 offset-1 offset-lg-0 opening-times d-flex info-column mb-1 mb-md-0">
                <SvgIconTime className="mr-1" />
                <div className="time">
                  {
                    optionsPage.edges[0].node.options.opening_times.opening_times.map((item, index ) => {
                      if (index < 3) {
                        return <span className="d-block text-m bold" key={item.opening_time_item}>{item.opening_time_item}</span>
                      }
                      return false
                    })
                  }
                </div>
              </div>
              <div className="col-10 col-lg-4 offset-1 offset-lg-0 address-info d-flex info-column mb-1 mb-md-0">
                <SvgIconMarker className="mr-1" />
                <div className="address">
                  <a href="https://www.google.com/maps/place/Jux+und+Tollerei/@48.14376,11.7786943,17z/data=!3m1!4b1!4m5!3m4!1s0x479e099a748861b9:0xb0e28d3485d8406e!8m2!3d48.14376!4d11.780883" target="_blank" rel="noopener noreferrer">
                    {
                      optionsPage.edges[0].node.options.footer.address.map(item => (
                        <span className="d-block text-m bold" key={item.address_item}>{item.address_item}</span>
                      ))
                    }
                  </a>
                </div>
              </div>
              <div className="col-10 col-md-5 col-lg-4 mt-lg-2 logo-climate-column flex-column">
                <a href="https://fpm.climatepartner.com/tracking/12949-1810-1001/de" target="_blank" rel="noopener noreferrer" className="climate-partner-link">
                  <SvgFooterClimatePartner />
                </a>
              </div>
              <div className="col-12 col-md-5 col-lg-4 logo-column">
                <SvgFooterLogo className="mb-1" />
              </div>
            </div>
          </div>
        </div>
        <div className="row footer-info mb-1 mb-md-2">
          <div className="col-12 col-lg-10 offset-lg-1 info-column">
            <div className="contact d-flex flex-column flex-lg-row row">
              {
                optionsPage.edges[0].node.options.footer.contact.map((item, index) => {
                  return (
                    index === 0 ? (
                      <div className="col-10 col-md-5 col-lg-4 offset-1 offset-lg-0 d-flex mb-1 mb-lg-0" key={item.contact_item}>
                        <SvgIconPhone className="mr-1" />
                        <a href={`tel:${item.contact_item}`}><span className="text-m bold">{item.contact_item}</span></a>
                      </div>
                    ) : (
                    index === 1 ? (
                      <div className="col-10 col-md-5 col-lg-4 offset-1 offset-lg-0 d-flex mb-1 mb-lg-0" key={item.contact_item}>
                        <SvgIconFax className="mr-1" />
                        <span className="text-m bold">{item.contact_item}</span>
                      </div>
                    ) : (
                      <div className="col-10 col-md-5 col-lg-4 offset-1 offset-lg-0 d-flex" key={item.contact_item}>
                        <SvgIconMail className="mr-1" />
                        <a href={`mailto:${item.contact_item}`} target="_blank" rel="noopener noreferrer" className="text-m bold" key={item.contact_item}>{item.contact_item}</a>
                      </div>
                    ))
                  )
                })
              }
            </div>
          </div>
        </div>
        <div className="row footer-bottom justify-content-between mt-2 mt-lg-0 pb-1">
          <FooterNav navItems={footerMenu.node.items} classes="d-flex col-10 offset-1" />
        </div>
      </div>
    </footer>
  )
}

export default Footer