import React from "react";
import cn from "classnames";

import "./Chip.scss";

const Chip = (props) => {
  const { headline, text, classes } = props
  return (
    <div className={cn("chip rounded-shadow", classes)}>
      {
        headline && <span>{headline}</span>
      }
      <br />
      {
        text && <span>{text}</span>
      }
    </div>
  );
}

export default Chip
