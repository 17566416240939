import React from "react";
import classNames from "classnames";
import "./HeadlineAndText.scss";

import { ReactComponent as Confetti } from "static/assets/brand/icons/confetti.svg";

const HeadlineAndText = (props) => {
  const { headline, text, link, linkText, isSubHeadline, hasIcons } = props;

  return (
    <div className={classNames(hasIcons && "has-icons d-flex flex-column justify-content-between", "text-container mx-auto text-center")}>
      <Confetti className={classNames(hasIcons && "has-icons d-md-block mt-2", "icon-confetti d-none")} />
      {
        isSubHeadline && headline && <h2 className="my-2">{headline}</h2>
      }
      {
        !isSubHeadline && headline && <h1 className="my-2">{headline}</h1>
      }
      {
        text && <div className="text-m my-2" dangerouslySetInnerHTML={{ __html: text }} />
      }
      {
        link && linkText && <a className="text-l color-orange no-underline mb-md-2" href={link}>{linkText}</a>
      }
      {
        link && !linkText && <a className="text-l color-orange no-underline mb-md-2" href={link}>Download</a>
      }
      <Confetti className={classNames(hasIcons && "has-icons d-md-block mt-2", "icon-confetti d-none")} />
    </div>
  );
}

export default HeadlineAndText