import React from "react";
import Chip from "Accessories/Chip/Chip"
import HeadlineAndText from "Accessories/HeadlineAndText/HeadlineAndText"
import classNames from "classnames";

import "./Hero.scss";

const Hero = (props) => {
  const { headline, text, openingTimes, waitingTime, hasIcons} = props
  return (
    <div className={classNames("hero-section on-white container-fluid pt-6 pt-md-4 position-relative pb-2", text && "is-hero-full pb-2")}>
      <div className="container position-relative">
        <div className="row text-align-center">
          <div className={classNames(hasIcons && "mb-lg-2", "col-12 col-md-10 col-lg-8 mx-auto")}>
            <HeadlineAndText headline={headline} text={text} hasIcons={hasIcons} />
          </div>
        </div>
      </div>
      {
        waitingTime && waitingTime !== "short" && (
          <Chip
            headline="Aktuelle Wartezeiten:"
            text={waitingTime === "short" ? "Kurze Wartezeit" : "Lange Wartezeit"}
            classes="waiting-time-chip bg-red position-absolute"
          />
        )
      }
      {
        openingTimes && (
          <div className="badge opening-times-badge circle bg-yellow mx-auto mx-lg-0">
            <span className="mb-1 d-inline-block">{openingTimes.opening_times_headline}</span>
            {
              openingTimes.opening_times.map(item => (
                <span className="d-block" key={item.opening_time_item}>{item.opening_time_item}</span>
              ))
            }
            <span className="d-block mt-1">{openingTimes.opening_times_additional_notice}</span>
          </div>
        )
      }
    </div>
  );
}

export default Hero
